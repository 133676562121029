import React from "react";
import { css } from "@emotion/react";

import Layout from "../components/layout";

import { TitleHeader, wrapper, Banner } from "../components/defaults/styles/elements"
import Info from "../components/defaults/assets/svg/info.svg"
import GeneralFooter from '../components/general_footer';
import { color } from "../components/defaults/styles/theme";
import { WipEl } from '../components/defaults/styles/wip';

const seo = {
  frontmatter: {
    title: 'Agenda' ,
  },
}

const UnderConstruction = () => {
    return (
      <>
        <Layout seo={seo}>
          <TitleHeader
            title='The EXPO 9 Agenda'
            subtitle="Below you will find our agenda: coming soon. Our day will be structured in three parts: a public stream on this website, breakout sessions led by our partners and startups, and an engaging virtual networking session."
            icon={<Info />}
          />
          <Banner type="info" content="<p>Breakout Sessions will be announced soon. Ticket holders will be able to register for the sessions before the event.</p>" 
          css={css`
            z-index: 1;
            position: relative;
            a {
              white-space: nowrap;
            }
          `}
          />

          <div
            css={[
              css`
                background: ${color.main_light};
                padding: 4rem 0;
                margin-top: -2.5em;
              `,
            ]}
          >

            <section
              css={[
                wrapper,
                css`
                  
                `,
              ]}
            >
              <WipEl pageTitle="EXPO 9 Agenda"/>
            </section>
          </div>

          <GeneralFooter theme={color.main_light} />
        </Layout>
      </>
    )
}

export default UnderConstruction;