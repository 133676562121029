import React from "react";
import { css } from "@emotion/react";
import  Icon from "../assets/svg/info.svg";
import { color } from "./theme";
import mq from "./mediaquerys"

export const WipEl = ({className, pageTitle}) => {
    return (
      <section
        className={className}
        css={[
          css`
            background: ${color.accent_light};
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            margin-bottom: 7em;
            margin-top: 2.5em;
            ${mq[1]} {
              min-height: 25rem;
            }
          `,
        ]}
      >
        <div
          css={css`
            display: flex;
            align-items: baseline;
            max-width: 35em;
            flex-direction: column;
            ${mq[1]} {
              flex-direction: row;
            }
          `}
        >
          <div
            css={css`
              padding: 0.5em 0.5rem;
              background: ${color.main_dark};
              border-radius: 5px;
              line-height: 1em;
              margin-right: 0.75em;
              margin-bottom: 0.75em;
              display: inline-block;
              ${mq[1]} {
                margin-bottom: 0;
              }
              svg {
                height: 1rem;
                width: auto;
                line-height: 1em;
                stroke: white;
              }
            `}
          >
            <Icon />
          </div>
          <div
            css={css`
              p {
                font-size: 0.85em;
                color: ${color.main_dark};
                line-height: 1.6em;
                padding-bottom: 0.5em;
                margin: 0;
                &:last-of-type {
                    padding-bottom: 0;
                }
              }
            `}
          >
            <p>
              The "{pageTitle}" section is currently under construction and will
              be gradually equipped with additional information once it is
              ready.
            </p>
            <p>
              Please make sure to visit this page freuqently to do not miss out
              any updates or follow us on social media for the most recent news
              about EXPO 8.
            </p>
          </div>
        </div>
      </section>
    )
};
